import { Gallery } from "react-grid-gallery";
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox'
import ImageViewer from './ImageViewer';

import img1 from './img/1.png';
import img2 from './img/2.jpg';
import img3 from './img/3.jpg';
import img4 from './img/4.jpg';
import img5 from './img/5.jpg';
import img6 from './img/6.jpg';
import img7 from './img/7.jpg';
import img8 from './img/8.jpg';


const images = [
    {
        src: img1,
        width: 1056,
        height: 1602
    },
    {
        src: img2,
        width: 989,
        height: 1280
    },
    {
        src: img3,
        width: 960,
        height: 1280
    },
    {
        src: img4,
        width: 960,
        height: 1280
    },
    {
        src: img5,
        width: 1280,
        height: 960
    },
    {
        src: img6,
        width: 960,
        height: 1280
    },
    {
        src: img7,
        width: 853,
        height: 1280
    },
    {
        src: img8,
        width: 1280,
        height: 960
    }
];

const AboutUsSection = ({ title, text, id }) => {
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const openLightbox = (index) => {
        setPhotoIndex(index);
        setLightboxIsOpen(true);
    };

    const closeLightbox = () => {
        setLightboxIsOpen(false);
    };

    return (
        <div>
            <Gallery
                images={images}
                rowHeight={300}
                onClickThumbnail={(i) => openLightbox(i)} />
            {lightboxIsOpen && (
                <Lightbox
                    mainSrc={images[photoIndex].src}
                    nextSrc={images[(photoIndex + 1) % images.length].src}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
                    onCloseRequest={closeLightbox}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                />
            )}
        </div>
    );
};

export default AboutUsSection;
